import React from "react"
import PropTypes from "prop-types"

import Header from "../components/header"
import Footer from "./footer"

const Layout = ({ children, className }) => {
  return (
    <>
      <Header />
      <div style={{ margin: `0 auto`, paddingTop: 0 }}>
        <main className={className}>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
