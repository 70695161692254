import { useStaticQuery, graphql } from "gatsby"

export const useNavItems = () => {
  const data = useStaticQuery(graphql`
    {
      sanitySettings {
        nav {
          _key
          to
          title
          isUnpublished
          isRestricted
          childPages {
            title
            to
            _key
          }
        }
      }
    }
  `)

  return data.sanitySettings.nav || []
}
