import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"

import { useNavItems } from "../../hooks/useNavItems"
import { isBrowser } from "../../utils/auth"
import { Button } from "../button"

import Logo from "../logo"

function ParentItem({ item }) {
  const [active, setActive] = useState(false)
  const { title } = item

  const Icon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    )
  }

  return (
    <div
      className="relative px-3 xl:px-4 py-2 text-gray-700 hover:text-black hover:bg-gray-200 rounded"
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <Link to={item.to}>
        {title}

        <span className="inline-block ml-1 relative top-1">
          <Icon />
        </span>
      </Link>

      {active && (
        <ul className="absolute left-0 top-10 z-10 w-56 bg-white px-4 py-2 rounded-sm rounded-t-none shadow-md">
          {item.childPages.map((page) => {
            return (
              <li key={page._key}>
                <Link
                  to={page.to}
                  className="relative text-gray-700 hover:text-indigo-900 my-2 block"
                >
                  {page.title}
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

function Header() {
  const { logout, isAuthenticated } = useAuth0()
  const [mobileMenu, toggleMobileMenu] = useState(false)
  const navItems = useNavItems()

  // Logout with service
  const handleLogout = () => {
    if (isBrowser) {
      localStorage.setItem("isLoggedIn", false)
    }

    logout()
  }

  useEffect(() => {
    if (isBrowser) {
      document.body.style.overflow = mobileMenu ? "hidden" : ""
    }
  }, [mobileMenu])

  return (
    <header className="bg-white shadow">
      <div className="container mx-auto px-3 py-2 flex justify-between">
        <Link to="/" className="flex flex-shrink-0 items-center">
          <Logo />
          <h1 className="serif text-sm lg:text-lg xl:text-xl font-bold">
            British Society <i className="font-normal text-gray-700">of</i>{" "}
            <span className="block">Skeletal Radiologists</span>
          </h1>
        </Link>

        <div className="lg:hidden ml-auto text-sm md:text-base">
          <Button
            className="pl-2 pr-2"
            onClick={() => {
              toggleMobileMenu(true)
            }}
            isInverted
            size="small"
          >
            Menu
          </Button>
        </div>

        <div className="lg:flex justify-end flex-row flex-wrap">
          <div className="hidden w-full justify-end mt-4 xl:mt-6 font-semibold lg:flex items-center">
            {isAuthenticated ? (
              <>
                <span
                  role="button"
                  tabIndex="0"
                  onClick={() => handleLogout()}
                  onKeyDown={(e) => e.key === "Enter" && handleLogout()}
                  className="px-1 lg:px-3 py-1 mx-1 hover:bg-gray-200 rounded capitalize cursor-pointer"
                >
                  Logout
                </span>

                {isAuthenticated && (
                  <a
                    href="https://forum.bssr.org.uk"
                    target="_blank"
                    className="px-1 lg:px-3 py-1 mx-1 mr-2 hover:bg-gray-200 rounded capitalize"
                  >
                    Member's Forum
                  </a>
                )}
              </>
            ) : (
              <Link
                to="/login"
                className="px-1 lg:px-3 py-1 mx-1 hover:bg-gray-200 rounded capitalize"
              >
                Sign in
              </Link>
            )}
            <Link
              to={isAuthenticated ? `/account` : `/register`}
              className="px-1 lg:px-3 py-1 ml-1 mr-0 bg-indigo-900 text-white hover:bg-gray-900 rounded"
            >
              {isAuthenticated ? `View my account` : `Join the BSSR`}
            </Link>
          </div>

          <nav className="hidden w-full justify-end lg:mr-0 mt-4 font-semibold lg:flex items-center pb-2">
            {navItems.map((item) => {
              if (item.isUnpublished) return null
              if (item.isRestricted && !isAuthenticated) return null

              if (item.childPages?.length > 0) {
                return <ParentItem item={item} />
              }

              return (
                <Link
                  key={item._key}
                  to={item.to}
                  className="px-3 xl:px-4 py-2 text-gray-700 hover:text-black hover:bg-gray-200 rounded"
                >
                  {item.title}
                </Link>
              )
            })}
          </nav>

          {mobileMenu && (
            <nav
              id="mobileMenu"
              className="z-50 fixed top-0 left-0 h-screen w-screen p-8 bg-gray-800 text-white"
            >
              <button
                onClick={() => {
                  toggleMobileMenu(false)
                  document.body.style.overflow = "auto"
                }}
                className="float-right font-bold px-2 py-1 border-2 border-white text-white hover:bg-white hover:text-indigo-900 rounded"
              >
                Close
              </button>

              <div className="mx-auto text-center mt-16 text-xl font-semibold">
                {navItems.map((item) => {
                  if (item.isUnpublished) return null
                  if (item.isRestricted && !isAuthenticated) return null

                  return (
                    <Link
                      key={item._key}
                      to={item.to}
                      className="px-3 xl:px-4 py-2 text-gray-700 hover:text-black hover:bg-gray-200 rounded"
                    >
                      {item.title}
                    </Link>
                  )
                })}

                <div className="border-t-2 my-2" />
                <div className="flex justify-center flex-col items-center mx-auto">
                  {isAuthenticated ? (
                    <>
                      <Link to="/account" className="px-6 py-2 mx-1 text-white">
                        My account
                      </Link>
                      <a
                        href="https://forum.bssr.org.uk"
                        target="_blank"
                        rel="noreferrer"
                        className="block px-6 py-2 mx-1 text-white"
                      >
                        Member's Forum
                      </a>
                      <Link to="/logout" className="px-6 py-2 mx-1 text-white">
                        Log out
                      </Link>
                    </>
                  ) : (
                    <Link
                      to="/login"
                      className="block px-6 py-2 mx-1 text-white"
                    >
                      Sign in
                    </Link>
                  )}
                  {!isAuthenticated && (
                    <Link
                      to="/register"
                      className="block px-6 mt-2 py-2 mx-1 rounded text-white bg-gray-900 hover:bg-indigo-700"
                    >
                      Join the BSSR
                    </Link>
                  )}
                </div>
              </div>
            </nav>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
