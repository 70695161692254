import React from "react"
import { format } from "date-fns"

function Footer() {
  return (
    <footer className="border-t border-gray-200 my-16 pt-12 leading-relaxed">
      <div className="container px-3 mx-auto text-gray-600 lg:flex items-center justify-between">
        <div>
          <p className="text-xs uppercase tracking-wide mb-0">
            {format(new Date(), "do MMMM yyyy")}
          </p>
          <span className="block serif text-black">
            British Society <i className="text-gray-600">of</i> Skeletal
            Radiologists
          </span>
          <p>
            All rights reserved. Content may not be reproduced without express
            permission unless otherwise stated.
          </p>
        </div>

        <div id="footerSocial" className="py-8 lg:p-1">
          <a
            href="https://twitter.com/BSSR_Site"
            target="_blank"
            className="inline-flex twitter bg-indigo-900 hover:bg-blue-500 text-white rounded p-1 mr-1"
            rel="noopener noreferrer"
          >
            <svg
              className="fill-current w-10 h-10"
              id="twitter"
              viewBox="0 0 50 50"
            >
              <path
                d="M40.9,14.5c-1.2,0.5-2.4,0.9-3.7,1c1.3-0.8,2.4-2.1,2.9-3.6c-1.3,0.7-2.6,1.3-4.1,1.6 c-1.2-1.3-2.9-2.1-4.7-2.1c-3.6,0-6.5,2.9-6.5,6.5c0,0.5,0.1,1,0.2,1.5c-5.4-0.3-10.2-2.9-13.4-6.8c-0.6,1-0.9,2.1-0.9,3.3 c0,2.3,1.1,4.2,2.9,5.4c-1.1,0-2.1-0.3-2.9-0.8c0,0,0,0.1,0,0.1c0,3.2,2.2,5.8,5.2,6.4c-0.5,0.1-1.1,0.2-1.7,0.2
             c-0.4,0-0.8,0-1.2-0.1c0.8,2.6,3.2,4.5,6.1,4.5c-2.2,1.7-5,2.8-8.1,2.8c-0.5,0-1,0-1.6-0.1c2.9,1.8,6.3,2.9,10,2.9 c12,0,18.5-9.9,18.5-18.5c0-0.3,0-0.6,0-0.8C38.9,17,40,15.9,40.9,14.5z"
              ></path>
            </svg>
          </a>

          <a
            href="https://www.facebook.com/theBSSR"
            target="_blank"
            className="inline-flex bg-indigo-900 hover:bg-indigo-700 text-white rounded p-1 mr-1"
            rel="noopener noreferrer"
          >
            <svg
              className="fill-current w-10 h-10"
              id="facebook"
              viewBox="0 0 50 50"
            >
              <path d="M33.9,43.1h-7.4V25h-3.7v-6.3h3.7V15c0-5.1,2.1-8.1,8.1-8.1h5v6.3h-3.1c-2.3,0-2.5,0.9-2.5,2.5l0,3.1h5.6L38.9,25h-5 L33.9,43.1L33.9,43.1z"></path>
            </svg>
          </a>

          {/* <a
            href="https://linkedin.com"
            target="_blank"
            className="inline-flex bg-indigo-900 hover:bg-blue-700 text-white rounded p-1 mr-1"
            rel="noopener noreferrer"
          >
            <svg
              className="fill-current w-10 h-10"
              id="linkedin"
              viewBox="0 0 50 50"
            >
              <path
                d="M11,37.4V19.1h6.1v18.3H11z M14.1,16.6L14.1,16.6c-2.1,0-3.4-1.4-3.4-3.1s1.3-3.1,3.4-3.1s3.4,1.4,3.4,3.1
             S16.2,16.6,14.1,16.6L14.1,16.6z"
              ></path>
              <path
                id="path30"
                d="M20.4,37.4c0,0,0.1-16.6-0.1-18.4h6.1v2.7l0,0c0.8-1.2,2.2-3,5.5-3c4.1,0,7,2.6,7,8.2v10.5h-6.1v-9.8 c0-2.5-0.8-4.2-3-4.2c-1.7,0-2.7,1.1-3.1,2.2c-0.2,0.4-0.2,0.9-0.2,1.5v10.3H20.4L20.4,37.4z"
              ></path>
            </svg>
          </a> */}
        </div>
      </div>
    </footer>
  )
}

export default Footer
